<script>
import Layout from "../../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../../app.config";
import axios from "axios";
import Swal from "sweetalert2";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { ref } from "vue";
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";

export default {
    page: {
        title: "Bot Details",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    setup() {
        const contentQuil = ref("")

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        return { contentQuil, Toast };
    },
    data() {
        return {
            title: "Detil Bot",
            items: [
                {
                    text: "Auto reply",
                    href: "/",
                },
                {
                    text: "Bot Details",
                    active: true,
                },
            ],
            content: "",
            first: "",
            id: 0,
            option: [],
            optionDetil: [],
            // for add
            botList: [],
            botOptionList: [
                {
                    ID: 0,
                    OptionText: "",
                    IsAssign: false,
                    ReplyWith: 0,
                    Sort: 1,
                },
            ],
            botExclude: [],
            botSelected: [],
            optionUpdateId: 0,
            defaultOptions: {
                animationData: animationData
            },
        };
    },
    watch: {
        $route(to){
            if(to.params.id.length > 1){
                let lastIdx = to.params.id.length - 1;
                this.getBotById(to.params.id[lastIdx]);
            } else {
                this.getBotById(to.params.id[0]);
            }
        }
    },
    methods: {
        async fetchChatBot(id, idx) {
            let url = `${process.env.VUE_APP_URL_API}/chat-bot/getBot/${id}`;
            const req = await axios
                .get(url, {
                    params: {
                        ClientID: process.env.VUE_APP_CLIENT_ID,
                        AccessToken: localStorage.getItem("jwt"),
                    },
                })
                .catch((err) => {
                    console.log(err);
                });

            let data = req.data;
            if (data.Meta.Code === 200) {
                let contentDB = data.Data.Content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '');
                let childern = {
                    id: data.Data.ID,
                    content: contentDB,
                    option: data.Data.Option,
                };
                this.optionDetil[idx]["botReply"] = childern;
            }
        },
        addOption() {
            let sort = this.botOptionList.length + 1;
            this.botOptionList.push({
                ID: 0,
                OptionText: "",
                IsAssign: false,
                ReplyWith: 0,
                Sort: sort,
            });
        },
        removeOption(data) {
            let idxData = this.botOptionList.findIndex((el) => {
                return el.Sort === data.Sort;
            });
            if (idxData !== -1) {
                this.botOptionList.splice(idxData, 1);
                this.botOptionList.forEach((item, i) => {
                    this.botOptionList[i]["Sort"] = i + 1;
                });
            }
        },
        loadHtml(delta) {
            this.contentQuil = delta;
        },
        cancelAddOption() {
            let removeList = this.botOptionList.filter((item) => {
                return item.ID === 0;
            });

            removeList.forEach((item) => {
                var idx = this.botOptionList.findIndex((el) => {
                    return el.Sort === item.Sort;
                });

                this.botOptionList.splice(idx, 1);
            });
        },
        addbot() {
            var optionData = [];
            this.botOptionList.forEach((row) => {
                optionData.push({
                    Text: row.OptionText,
                    IsAssign: row.IsAssign,
                    ReplyWith: row.ReplyWith,
                    Sort: row.Sort,
                });
            });

            var data = JSON.stringify({
                Content: this.contentQuil,
                FirstChat: document.getElementById("idFirstChat").checked,
                KodeMB: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
                Option: optionData,
                UpdateOption: document.querySelector("#botId-optionId").getAttribute("data-option-id")
            });

            let url = process.env.VUE_APP_URL_API + "chat-bot/createBot";
            url +=
                "?ClientID=" +
                process.env.VUE_APP_CLIENT_ID +
                "&AccessToken=" +
                localStorage.getItem("jwt");
            axios
                .post(url, data)
                .then((res) => {
                    if(res.data.Meta.Code === 200){
                        this.getBotById(this.id);
                        this.Toast.fire({
                            icon: 'success',
                            title: 'Bot reply success added'
                        })
                    } else {
                        this.Toast.fire({
                            icon: 'warning',
                            title: res.data.Meta.Message
                        })
                    }
                })
                .catch((er) => {
                    console.log(er);
                    Swal.fire("Fails!", er.message, "error");
                });

            document.getElementById("closemodal").click();
            document.getElementById("addform").reset();
        },
        addnew(data) {
            this.content = "";
            this.$refs.quilEditor.setContents(this.content);
            this.botOptionList = [
                {
                    ID: 0,
                    OptionText: "",
                    IsAssign: false,
                    ReplyWith: 0,
                    Sort: 1,
                },
            ];

            document.getElementById("addform").reset();
            document.getElementById("modal-id").style.display = "none";
            document.getElementById("exampleModalLabel").innerHTML = "Tambah Jawaban Bot";
            document.getElementById("add-btn").style.display = "block";
            document.getElementById("edit-btn").style.display = "none";

            document.querySelector("#botId-optionId").setAttribute("data-bot-id", this.id);
            document.querySelector("#botId-optionId").setAttribute("data-option-id", data.id);
        },
        editdata(data) {
            document.getElementById("modal-id").style.display = "block";
            document.getElementById("exampleModalLabel").innerHTML = "Edit Bot Chat";

            document.getElementById("botId").value = data.id;
            this.$refs.quilEditor.setContents(data.content);
            this.contentQuil = data.content;
            this.botOptionList = data.option;

            document.getElementById("idFirstChat").checked = data.FirstChat;

            document.getElementById("edit-btn").style.display = "block";
            document.getElementById("add-btn").style.display = "none";

            this.botExclude = [];
            this.botSelected = [];
            var url = `${process.env.VUE_APP_URL_API}chat-bot/getBotExclude/${data.id}`;
            axios
                .get(url)
                .then((res) => {
                    let json = res.data;
                    if (json.Meta.Code === 200) {
                        this.botSelected = json.Data.Selected;
                        json.Data.Option.forEach((row) => {
                            this.botExclude.push({
                                id: row.ID,
                                title: row.Content,
                            });
                        });
                    } else {
                        this.botExclude = [];
                    }
                })
                .catch((er) => {
                    this.botExclude = [];
                    console.log(er);
                });
        },
        updateorder() {

            let result = this.optionDetil.find(el => {
                return parseInt(el.botReply.id) === parseInt(document.getElementById("botId").value)
            })

            var id = result.botReply.id;

            var optionData = [];
            this.botOptionList.forEach((row) => {
                optionData.push({
                    ID: row.ID,
                    OptionText: row.OptionText,
                    IsAssign: row.IsAssign,
                    ReplyWith: row.ReplyWith,
                    Sort: row.Sort,
                });
            });

            var data = JSON.stringify({
                Content: this.contentQuil,
                FirstChat: false,
                KodeMB: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
                Option: optionData,
            });

            var url = `${process.env.VUE_APP_URL_API}chat-bot/updateChatBot/${id}?AccessToken=${localStorage.getItem("jwt")}&ClientID=${process.env.VUE_APP_CLIENT_ID}`;
            axios
                .post(url, data)
                .then(() => {
                    console.log("Success updated");
                    this.getBotById(this.id)
                    this.Toast.fire({
                        icon: "success",
                        text: "Data success updated"
                    })
                })
                .catch((er) => {
                    this.Toast.fire({
                        icon: "error",
                        text: "Internal Server Error"
                    })
                    console.log(er);
                });
            document.getElementById("closemodal").click();
        },
        unlinkanswer(parent){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, unlink it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = `${process.env.VUE_APP_URL_API}chat-bot/unlinkAnswer`
                    axios.get(url, {
                        params: {
                            BotID: this.id,
                            OptionID: parent.id,
                            AccessToken: localStorage.getItem("jwt"),
                            ClientID: process.env.VUE_APP_CLIENT_ID
                        }
                    }).then(res => {
                        if(res.data.Meta.Code === 200){
                            this.Toast.fire({
                                icon: "success",
                                text: "Unlink success"
                            })
                            
                            let idxOptionDetil = this.optionDetil.findIndex(el => el.id === parent.id);
                            if(idxOptionDetil !== -1){
                                this.getBotById(this.id);
                            }
                        } else {
                            this.Toast.fire({
                                icon: "warning",
                                text: res.data.Meta.Message
                            })
                        }
                    }).catch(() => {
                        this.Toast.fire({
                            icon: "error",
                            text: "Internal Server Error"
                        })
                    })
                }
            })
        },
        getBotById(id)
        {
            axios.get(`${process.env.VUE_APP_URL_API}/chat-bot/getBot/${id}`, {
                params: {
                    ClientID: process.env.VUE_APP_CLIENT_ID,
                    AccessToken: localStorage.getItem("jwt"),
                },
            }).then(async (res) => {
                let data = res.data;
                if (data.Meta.Code === 200) {
                    this.content = data.Data.Content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '');
                    this.first = data.Data.First;
                    this.id = data.Data.ID;
                    this.option = data.Data.Option;
                    this.optionDetil = [];
                    for (let _idx = 0; _idx < data.Data.Option.length; _idx++) {
                        const item = data.Data.Option[_idx];
                        this.optionDetil.push({
                            id: item.ID,
                            content: item.OptionText,
                            isAssign: item.IsAssign,
                            sort: item.Sort,
                            botReply: {},
                        });

                        if (item.ReplyWith !== 0) {
                            await this.fetchChatBot(
                                item.ReplyWith,
                                this.optionDetil.length - 1
                            );
                        }
                    }
                }
            })
            .catch((er) => {
                console.log(er);
                this.Toast.fire({
                    icon: "error",
                    text: "Internal Server Error"
                })
            });
        },
        getBotExclude(optionId)
        {
            this.optionUpdateId = optionId;
            this.botExclude = [];
            var url = `${process.env.VUE_APP_URL_API}chat-bot/getBotExclude/${this.id}`;
            axios.get(url).then((res) => {
                let json = res.data;
                if (json.Meta.Code === 200) {
                    this.botSelected = json.Data.Selected;
                    json.Data.Option.forEach((row) => {
                        if(!this.botSelected.includes(row.ID)){
                            this.botExclude.push({
                                id: row.ID,
                                title: row.Content,
                            });
                        }
                    });
                } else {
                    this.botExclude = [];
                }
            })
            .catch((er) => {
                this.botExclude = [];
                console.log(er);
            });
        },
        updateOptionBot(bot)
        {
            let optionUpdate = [];
            optionUpdate.push({
                ID: this.optionUpdateId,
                ReplyWith: bot.id
            })

            let formData = JSON.stringify({
                Content: this.content,
                FirstChat: this.first,
                KodeMB: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
                Option: optionUpdate
            })

            let url = `${process.env.VUE_APP_URL_API}chat-bot/updateChatBot/${this.id}`;
            axios.post(url, formData, {
                params: {
                    ClientID: process.env.VUE_APP_CLIENT_ID,
                    AccessToken: localStorage.getItem("jwt")
                }
            }).then(res => {
                if(res.data.Meta.Code === 200){
                    this.Toast.fire({
                        icon: 'success',
                        text: 'Bot success ditambahkan di opsi'
                    })
                    this.getBotById(this.id);
                    document.querySelector("#dismissModal").click();
                } else {
                    this.Toast.fire({
                        icon: 'warning',
                        text: res.data.Meta.Message
                    })
                }
            }).catch(err => {
                console.log(err);
                this.Toast.fire({
                    icon: 'error',
                    text: 'Internal Server Error'
                });
            })
        },
        async gotoDetil(id, back = false){
            let url = '';
            if(back){
                await this.$router.go(-1)
            } else {
                url = this.$route.fullPath+"/"+id;
                await this.$router.push(url)
            }
        },
        backLink(){
            let lengthId = this.$route.params.id.length;
            let id = this.$route.params.id[lengthId - 2];
            this.gotoDetil(id, true);
        },
        deleteBot(){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = `${process.env.VUE_APP_URL_API}chat-bot/deleteBot/${this.id}`
                    axios.get(url, {
                        params: {
                            ClientID: process.env.VUE_APP_CLIENT_ID,
                            AccessToken: localStorage.getItem("jwt")
                        }
                    }).then(res => {
                        if(res.data.Meta.Code === 200){
                            this.Toast.fire({
                                icon: 'success',
                                title: 'Delete Success'
                            })
                            this.backLink();
                        } else {
                            this.Toast.fire({
                                icon: 'error',
                                title: res.data.Meta.Message
                            })
                        }
                    }).catch(err => {
                        console.log(err);
                        this.Toast.fire({
                            icon: 'error',
                            title: 'Internal Server Error'
                        })
                    })
                }
            })
        }
    },
    beforeMount() {
        this.content = "";
        this.first = "";
        this.id = 0;
        this.option = [];
        this.optionDetil = [];

        if(this.$route.params.id.length > 1){
            let lastIdx = this.$route.params.id.length - 1;
            this.getBotById(this.$route.params.id[lastIdx]);
        }
    },
    mounted() {
        if(this.$route.params.id.length === 1){
            this.getBotById(this.$route.params.id[0]);
        }
    },
    components: {
        Layout,
        PageHeader,
        QuillEditor,
        lottie: Lottie,
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row justify-content-center">
            <div class="col-xxl-9">
                <button @click="backLink" type="button" class="btn btn-xmarks1 btn-label waves-effect waves-light rounded-pill"><i class="ri-arrow-left-line label-icon align-middle rounded-pill fs-16 me-2"></i> Kembali</button>
                <button v-if="this.$route.params.id.length > 1" @click="deleteBot" type="button" class="btn btn-danger btn-label waves-effect waves-light rounded-pill ms-2"><i class="ri-delete-bin-line label-icon align-middle rounded-pill fs-16 me-2"></i> Hapus Bot</button>
                <div class="card mt-4" id="demo">
                    <!--end card-header-->
                    <div class="card-body p-4">
                        <div class="row g-3">
                            <div class="col-lg-4 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">ID</p>
                                <h5 class="fs-14 mb-0">
                                    <span id="invoice-no">{{ id }}</span>
                                </h5>
                            </div>
                            <!--end col-->
                            <div class="col-lg-4 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">
                                    Content
                                </p>
                                <h5 class="fs-14 mb-0">
                                    <small class="text-muted" id="invoice-time">{{
                                        content
                                    }}</small>
                                </h5>
                            </div>
                            <!--end col-->
                            <div class="col-lg-4 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">
                                    Is First
                                </p>
                                <span class="badge fs-11" :class="[
                                            { 'bg-success': first == true },
                                            { 'bg-info': first == false },
                                        ]" id="payment-status">{{ first }}</span>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end card-body-->
                    <div class="card-body p-4 border-top border-top-dashed">
                        <!-- <div class="row g-3">
                            <div class="col-sm-6">
                                <h6 class="text-muted text-uppercase fw-semibold mb-3">
                                    Option
                                </h6>
                                <p class="fw-medium mb-2" v-for="opt in option" :key="opt.ID">
                                    {{ opt.Sort + ". " + opt.OptionText }}
                                    <span v-if="opt.IsAssign" class="badge bg-primary fs-10">Assign to Agent</span>
                                </p>
                            </div>
                        </div> -->
                        <!--end row-->
                    </div>
                    <!--end card-body-->
                    <div class="card-body p-4">
                        <div class="table-responsive">
                            <table class="table table-borderless text-center table-nowrap align-middle mb-0">
                                <thead>
                                    <tr class="table-active">
                                        <th scope="col" class="text-start" style="width: 50px">
                                            Customer Reply
                                        </th>
                                        <th scope="col">Reply Messages</th>
                                        <th scope="col">Reply Option</th>
                                        <th scope="col" class="text-end">Action</th>
                                    </tr>
                                </thead>
                                <tbody id="products-list">
                                    <tr v-for="(item, index) of optionDetil" :key="index">
                                        <th class="text-start" scope="row">
                                            {{ item.sort }}. {{ item.content }}
                                        </th>
                                        <td>
                                            <span v-if="!item.isAssign" class="fw-medium">{{ item.botReply.content }}</span>
                                            <span v-else class="fw-medium badge bg-primary">assign to agent</span>
                                        </td>
                                        <td>
                                            <ol>
                                                <li v-for="opt in item.botReply.option" :key="opt.id" class="text-start">
                                                    {{ opt.OptionText }}
                                                </li>
                                            </ol>
                                        </td>
                                        <td class="text-end">
                                            <button v-if="Object.keys(item.botReply).length !== 0"
                                                class="btn btn-sm btn-outline-success me-2"
                                                @click="gotoDetil(`${item.botReply.id}`)">
                                                Detil
                                            </button>
                                            <!-- <a v-if="Object.keys(item.botReply).length !== 0" data-bs-toggle="modal"
                                                data-bs-target="#showModal" class="btn btn-sm btn-outline-warning me-2"
                                                @click="editdata(item.botReply)">Edit</a> -->

                                            <a v-if="Object.keys(item.botReply).length !== 0" class="btn btn-sm btn-danger"
                                                @click="unlinkanswer(item)">Unlink</a>

                                            <a v-if="Object.keys(item.botReply).length === 0 && !item.isAssign" data-bs-toggle="modal"
                                                data-bs-target="#showModal" class="btn btn-sm btn-outline-info me-2"
                                                @click="addnew(item)">Add Reply</a>

                                            <a v-if="Object.keys(item.botReply).length === 0 && !item.isAssign" data-bs-toggle="modal"
                                                data-bs-target="#exampleModalScrollable" class="btn btn-sm btn-outline-success"
                                                @click="getBotExclude(item.id)">Select Reply</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--end table-->
                        </div>
                    </div>
                    <!--end card-body-->
                </div>
                <!--end card-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <!-- modal add / edit -->
        <div class="modal fade zoomIn" id="showModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content border-0">
                    <div class="modal-header p-3 bg-soft-info">
                        <h5 class="modal-title" id="exampleModalLabel"></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            id="close-modal"></button>
                    </div>
                    <form id="addform">
                        <div class="modal-body">
                            <div class="row g-3">
                                <input type="hidden" id="botId-optionId" data-bot-id="" data-option-id="" />
                                <div class="col-lg-12">
                                    <div id="modal-id">
                                        <label for="orderId" class="form-label">ID</label>
                                        <input type="text" id="botId" class="form-control" placeholder="ID" value=""
                                            readonly />
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div>
                                        <label for="tasksTitle-field" class="form-label">Content</label>
                                        <QuillEditor v-model:content="contentQuil" @update:content="loadHtml(contentQuil)"
                                            theme="snow" :toolbar="['bold', 'italic', 'strike']" contentType="html"
                                            placeholder="Input here" ref="quilEditor"></QuillEditor>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <!-- Switch sizes -->
                                    <div class="form-check form-switch" dir="ltr">
                                        <input type="checkbox" class="form-check-input" id="idFirstChat" />
                                        <label class="form-check-label" for="idFirstChat">Is First Chat</label>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-lg-1 text-center" style="margin-right: -20px">
                                        <label for="ticket-status" class="form-label">Sort</label>
                                    </div>
                                    <div class="col-lg-5">
                                        <label for="ticket-status" class="form-label">Option</label>
                                    </div>
                                    <div class="col-lg-2">
                                        <label for="priority-field" class="form-label">Is Assign</label>
                                    </div>
                                    <div class="col-lg-1"></div>
                                </div>
                                <div class="row mb-2" v-for="(opt, idx) in this.botOptionList" :key="opt.ID">
                                    <div class="col-lg-1 mt-2" style="margin-right: -20px">
                                        <p class="text-center">{{ opt.Sort }}</p>
                                    </div>
                                    <div class="col-lg-5">
                                        <input v-model="opt.OptionText" type="text" class="form-control"
                                            placeholder="Option Text" />
                                    </div>
                                    <div class="col-lg-2 mt-2">
                                        <input v-model="opt.IsAssign" class="form-check-input" type="checkbox"
                                            :id="'formCheck-' + idx" />
                                        Assign
                                    </div>
                                    <div class="col-lg-1">
                                        <button v-if="idx > 0 && !opt.IsAssign" @click="removeOption(opt)" type="button"
                                            class="btn btn-danger btn-sm btn-icon waves-effect waves-light">
                                            <i class="ri-delete-bin-5-line"></i>
                                        </button>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-primary" @click="addOption">
                                    Add Option
                                </button>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="hstack gap-2 justify-content-end">
                                <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="cancelAddOption"
                                    id="closemodal">
                                    Close
                                </button>
                                <button type="button" class="btn btn-success" id="add-btn" @click="addbot">
                                    Add Bot
                                </button>
                                <button type="button" class="btn btn-success" id="edit-btn" @click="updateorder">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- modal choese bot -->
        <!-- Scrollable Modal -->
        <div class="modal fade" id="exampleModalScrollable" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">
                            Select BOT is avaiable
                        </h5>
                        <button type="button" class="btn-close" id="dismissModal" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row" v-if="botExclude.length > 0">
                            <div class="col-6" v-for="bot in this.botExclude" :key="bot.id">
                                <div class="card bg-xmarks2">
                                    <div class="card-body">
                                        <p class="text-light">{{ bot.title }}</p>
                                        <!-- <p class="text-muted mb-0"></p> -->
                                    </div>
                                    <div class="card-footer text-center bg-xmarks1">
                                        <a href="javascript:void(0);" @click="updateOptionBot(bot, 0)" class="link-light">Pilih Bot <i class="ri-arrow-right-s-line align-middle ms-1 lh-1"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="text-center">
                                <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                                :height="75" :width="75" />
                                <h5 class="mt-2">Sorry! No Result Found</h5>
                                <p class="text-muted mb-0">Bot not found, please insert bot</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="modal-footer">
                        <button type="button" class="btn btn-light"
                            data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                    </div> -->
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
    </Layout>
</template>
